import React from "react"
import { graphql, Link } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import moment from "moment"
import ScrollableAnchor from "react-scrollable-anchor"

import ArtistGroup from "../components/festival/artist-group"
import Block from "../components/block/block"
import Button from "../components/button/button"
import Layout from "../components/layout/layout"
import FestivalHeader from "../components/festival/festival-header"
import FestivalNavigation from "../components/festival/festival-navigation"
import FestivalBackground from "../components/festival/festival-background"
import PageTransition from "../components/transition/page"
import SEO from "../components/seo/seo"
import PartnerGroup from "../components/festival/partner-group"
import ThumbnailGroup from "../components/thumbnail/thumbnail-group"
import GalleryBlock from "../components/block/gallery"

class LocationTemplate extends React.Component {

    constructor(props){
      super(props)
      this.pageTransition = React.createRef()

      this.exit = { length: 1.7, trigger: () => this.animate() }
      this.entry = { length: 1, appearAfter: 0.5 }
    }

    animate(){
      this.pageTransition.current.play()
    }

    render(){
        const locale = this.props.pageContext.locale
        const { location } = this.props.data
        return(
            <Layout theme="light" path={this.props.path} locale={locale} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
              <SEO
                title={location.seo.title}
                description={location.seo.description}
                keywords={location.seo.keywords && location.seo.keywords.keywords}
                image={location.seo.image.file.url}
              />
                <FestivalNavigation locale={locale} links={location.navigation.links} color={'#F5867B'} />
                <FestivalHeader wide={location.theme.widescreenHeaderImage.file.url} portrait={location.theme.portraitHeaderImage.file.url} animation={location.theme.animation.file.url}>
                  <div className={"container mx-auto py-20 sm:py-4 md:py-0 lg:py-20 text-center leading-none"}>
                    <p className={"font-formula-bold text-lg lg:text-2xl text-white uppercase tracking-wider"}>{moment.parseZone(location.date).format("ddd MMMM Do")}</p>
                    <p className={"font-montserrat-extra-bold text-3xl md:text-7xl lg:text-9xl text-white"}>{location.title}</p>
                    <p className={"font-formula-light text-xl lg:text-2xl text-white tracking-wider"}>{location.venue}</p>
                    <div className={"mx-auto text-center my-4 py-4 block md:hidden"}>
                      <Link to={`/${locale}/locations/${location.slug}/tickets`}>
                        <Button label={"ticket information"} type={"solid"} />
                      </Link>
                    </div>
                  </div>
                </FestivalHeader>
                <FestivalBackground wide={location.theme.widescreenBackground.file.url}>
                    <div>
                      <ScrollableAnchor id={'lineup'}>
                        <ArtistGroup 
                          artists={location.artists} 
                          locale={locale} 
                          colorSecondary={location.theme.colorSecondary} 
                          colorPrimary={location.theme.colorPrimary} 
                          localAct={location.localAct}
                          setTimes={location.setTimes}
                        />
                      </ScrollableAnchor>
                    </div>
                    <Block {...location.information} align={"center"} locale={locale} />
                    <GalleryBlock images={location.gallery.images} block={<Block {...location.about} align={"left"} locale={locale} />} />
                    <div>
                      <ScrollableAnchor id={'news'}>
                        <ThumbnailGroup 
                          title={"Festival news"} 
                          items={location.news}
                          locale={locale} 
                          href={`/${locale}/articles/`}
                        />
                      </ScrollableAnchor>
                    </div>
                    <div>
                      <ScrollableAnchor id={'partners'}>
                        <PartnerGroup partners={location.partners} locale={locale} />
                      </ScrollableAnchor>
                    </div>
                </FestivalBackground>
                <TransitionPortal>
                  <PageTransition ref={this.pageTransition} />
                </TransitionPortal>
            </Layout>
        )

    }

}

export const pageQuery = graphql`
query LocationPageQuery($slug: String!, $locale: String!) {
  location: contentfulLocation(slug: {eq: $slug}, node_locale: {eq: $locale}) {
    title
    slug
    date
    venue
    localAct
    setTimes
    seo {
      title
      description
      keywords {
        keywords
      }
      image {
        file {
          url
        }
      }
    }
    artists {
      ... on ContentfulArtist {
        title
        slug
        thumbnail{
          file{
            url
          }
        }
        image {
          file {
            url
          }
        }
      }
    }
    information{
      ... on ContentfulBlock {
        title
        slug
        images{
          file{
            url
          }
        }
        ctaText
        link
        content {
          json
        }
      }
    }
    about{
      ... on ContentfulBlock {
        title
        slug
        images{
          file{
            url
          }
        }
        ctaText
        link
        content {
          json
        }
      }
    }
    news {
      ... on ContentfulArticle {
        title
        slug
        short
        image {
          file {
            url
          }
        }
      }
    }
    gallery {
      ... on ContentfulGallery {
        images {
          file {
            url
          }
        }
      }
    }
    partners {
      ... on ContentfulPartner {
        slug
        logo {
          file {
            url
          }
        }
      }
    }
    theme {
      ... on ContentfulTheme {
        logo {
          file {
            url
          }
        }
        animation{
          file{
            url
          }
        }
        portraitBackground {
          file {
            url
          }
        }
        portraitHeaderImage {
          file {
            url
          }
        }
        widescreenBackground {
          file {
            url
          }
        }
        widescreenHeaderImage {
          file {
            url
          }
        }
        colorPrimary
        colorSecondary
      }
    }
    navigation{
      ...on ContentfulNavigation{
        links{
          ...on ContentfulLink{
            title
            href
          }
        }
      }
    }
  }
}
`

export default LocationTemplate
