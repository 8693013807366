import React from "react"

import Carousel from "../carousel/carousel"

class GalleryBlock extends React.Component {

    render(){
        return(
            <div className={"container mx-auto py-12"}>
                <div className={"flex flex-wrap"}>
                    <div className={"w-full lg:hidden"}>
                        <div className={"pt-20 px-4 md:px-4"}>
                            <Carousel>
                            {
                                this.props.images.map((item, index) => {
                                    return(<img src={item.file.url} key={`img-${index}`} className={"w-full"} alt="" />)
                                })
                            }
                            </Carousel>
                        </div>
                    </div>
                    <div className={"w-full lg:w-1/2 pr-4"}>
                        {this.props.block}
                    </div>
                    <div className={"hidden px-4 lg:px-0 lg:block lg:w-1/2"}>
                        <div className={"pt-8"}>
                            <Carousel>
                            {
                                this.props.images.map((item, index) => {
                                    return(<img src={item.file.url} key={`img-${index}`} alt="" />)
                                })
                            }
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default GalleryBlock